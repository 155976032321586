import styled from 'styled-components';

import gradientBg from 'src/images/svgs/fireship/Bg_gradient.jpg';

export const StyledBar = styled.div<{ isCongratulationModal?: boolean }>`
  height: 6rem;
  background: rgba(21, 28, 43, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  gap: 3.2rem;
  position: relative;
  overflow: hidden;
  width: auto;
  padding: ${({ isCongratulationModal }) => (isCongratulationModal ? '1rem 2rem' : '0')};
  border-radius: ${({ isCongratulationModal }) => (isCongratulationModal ? '5px' : '0')};
  @media (max-width: ${({ theme }) => theme.sizes.md}) {
    display: block;
    height: auto;
    padding: 1.2rem;
    text-align: center;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${gradientBg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  p {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    @media (max-width: ${({ theme }) => theme.sizes.md}) {
      margin-bottom: 1rem;
    }
  }

  p span {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  a {
    position: relative;
    z-index: 1;
    &:hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
